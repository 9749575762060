import { type UserRoles } from '@bibeltv-leseplaene/api'
import { type RouteLocationNormalized } from 'vue-router'

export function checkUserAuth(allowedRoles: UserRoles[]) {
  const auth = useAuth()

  if (
    !auth ||
    !auth.session.user ||
    !allowedRoles.some(
      (allowedRole) => auth.session.user?.roles.some((role) => role.name === allowedRole),
    )
  ) {
    return false
  }
  return true
}

export function enforceAuth(route: RouteLocationNormalized) {
  if (Array.isArray(route.meta.auth) && !checkUserAuth(route.meta.auth as UserRoles[])) {
    return navigateTo('/kategorien')
  }
}

export default defineNuxtRouteMiddleware(async (to) => {
  return await enforceAuth(to)
})
