import { type AppRouter } from '@bibeltv-leseplaene/api'
import { httpLink } from '@trpc/client'
import superjson from 'superjson'
import { createTRPCNuxtClient } from 'trpc-nuxt/client'

export default defineNuxtPlugin({
  enforce: 'pre',
  setup() {
    const config = useRuntimeConfig()
    const client = createTRPCNuxtClient<AppRouter>({
      transformer: superjson,
      links: [
        httpLink({
          url: `${config.public.apiBaseUrl}/trpc`,
          headers: {
            authorization: `Bearer`,
          },
        }),
      ],
    })

    return {
      provide: {
        trpc: client,
      },
    }
  },
})
