import { default as index0KJcXeJIlnMeta } from "/app/packages/web/pages/index.vue?macro=true";
import { default as kategorienrbaLghitvWMeta } from "/app/packages/web/pages/kategorien.vue?macro=true";
import { default as leseplaeneNPzalpPmTcMeta } from "/app/packages/web/pages/leseplaene.vue?macro=true";
import { default as _91id_93DHOuZlt18RMeta } from "/app/packages/web/pages/leseplan/[id].vue?macro=true";
import { default as reviewszb5bXOiqgFMeta } from "/app/packages/web/pages/reviews.vue?macro=true";
import { default as usersyGbKDt4uZWMeta } from "/app/packages/web/pages/users.vue?macro=true";
export default [
  {
    name: index0KJcXeJIlnMeta?.name ?? "index",
    path: index0KJcXeJIlnMeta?.path ?? "/",
    meta: index0KJcXeJIlnMeta || {},
    alias: index0KJcXeJIlnMeta?.alias || [],
    redirect: index0KJcXeJIlnMeta?.redirect || undefined,
    component: () => import("/app/packages/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kategorienrbaLghitvWMeta?.name ?? "kategorien",
    path: kategorienrbaLghitvWMeta?.path ?? "/kategorien",
    meta: kategorienrbaLghitvWMeta || {},
    alias: kategorienrbaLghitvWMeta?.alias || [],
    redirect: kategorienrbaLghitvWMeta?.redirect || undefined,
    component: () => import("/app/packages/web/pages/kategorien.vue").then(m => m.default || m)
  },
  {
    name: leseplaeneNPzalpPmTcMeta?.name ?? "leseplaene",
    path: leseplaeneNPzalpPmTcMeta?.path ?? "/leseplaene",
    meta: leseplaeneNPzalpPmTcMeta || {},
    alias: leseplaeneNPzalpPmTcMeta?.alias || [],
    redirect: leseplaeneNPzalpPmTcMeta?.redirect || undefined,
    component: () => import("/app/packages/web/pages/leseplaene.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DHOuZlt18RMeta?.name ?? "leseplan-id",
    path: _91id_93DHOuZlt18RMeta?.path ?? "/leseplan/:id()",
    meta: _91id_93DHOuZlt18RMeta || {},
    alias: _91id_93DHOuZlt18RMeta?.alias || [],
    redirect: _91id_93DHOuZlt18RMeta?.redirect || undefined,
    component: () => import("/app/packages/web/pages/leseplan/[id].vue").then(m => m.default || m)
  },
  {
    name: reviewszb5bXOiqgFMeta?.name ?? "reviews",
    path: reviewszb5bXOiqgFMeta?.path ?? "/reviews",
    meta: reviewszb5bXOiqgFMeta || {},
    alias: reviewszb5bXOiqgFMeta?.alias || [],
    redirect: reviewszb5bXOiqgFMeta?.redirect || undefined,
    component: () => import("/app/packages/web/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: usersyGbKDt4uZWMeta?.name ?? "users",
    path: usersyGbKDt4uZWMeta?.path ?? "/users",
    meta: usersyGbKDt4uZWMeta || {},
    alias: usersyGbKDt4uZWMeta?.alias || [],
    redirect: usersyGbKDt4uZWMeta?.redirect || undefined,
    component: () => import("/app/packages/web/pages/users.vue").then(m => m.default || m)
  }
]