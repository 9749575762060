import { useQuery } from '@tanstack/vue-query'
import { defineStore } from 'pinia'

export const useAuth = defineStore('session', () => {
  const trpc = useTRPC()
  const {
    data: user,
    error: userError,
    isLoading: userLoading,
    refetch: refresh,
  } = useQuery({
    queryKey: ['me'] as const,
    queryFn: () => trpc.auth.me.query(),
    staleTime: 0,
    retry: false,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  })

  type SessionState =
    | {
        user: (typeof user)['value']
        isCreator: boolean
        isModerator: boolean
        isAdmin: boolean
        status: 'authenticated'
      }
    | {
        user: null
        isCreator: false
        isModerator: false
        isAdmin: false
        status: 'unauthenticated' | 'loading'
      }

  const session = computed(() => {
    return {
      user: userError.value ? null : user.value,
      isCreator: userError.value
        ? false
        : user.value?.roles.some((role) => role.name === 'CREATOR'),
      isModerator: userError.value
        ? false
        : user.value?.roles.some((role) => role.name === 'MODERATOR'),
      isAdmin: userError.value ? false : user.value?.roles.some((role) => role.name === 'ADMIN'),
      status: userLoading.value
        ? 'loading'
        : !userError.value && user.value
        ? 'authenticated'
        : 'unauthenticated',
    } as SessionState
  })

  async function signIn(userId: number) {
    return trpc.auth.selectUser.mutate(userId).then(() => {
      nextTick(() => {
        location.reload()
      })
    })
  }

  return {
    refresh,
    session,
    signIn,
  }
})
