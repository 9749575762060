import revive_payload_client_kG6mp5Xpfk from "/app/node_modules/.pnpm/nuxt@3.7.1_@parcel+watcher@2.3.0_@types+node@18.17.14_encoding@0.1.13_eslint@8.49.0_optionato_ob7b236jhsabowihs5e6dacmlm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_WpxwByvOnn from "/app/node_modules/.pnpm/nuxt@3.7.1_@parcel+watcher@2.3.0_@types+node@18.17.14_encoding@0.1.13_eslint@8.49.0_optionato_ob7b236jhsabowihs5e6dacmlm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_EJJu3XmsQk from "/app/node_modules/.pnpm/nuxt@3.7.1_@parcel+watcher@2.3.0_@types+node@18.17.14_encoding@0.1.13_eslint@8.49.0_optionato_ob7b236jhsabowihs5e6dacmlm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_Ub7jFNgAqV from "/app/packages/web/modules/sentry/runtime/sentry.client.ts";
import trpc_client_3tK3TWUrV0 from "/app/packages/web/plugins/trpc.client.ts";
import plugin_vue3_38Il8GJ5wi from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@3.29.0_typescript@5.2.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/packages/web/.nuxt/components.plugin.mjs";
import prefetch_client_Ctuj9Yxhme from "/app/node_modules/.pnpm/nuxt@3.7.1_@parcel+watcher@2.3.0_@types+node@18.17.14_encoding@0.1.13_eslint@8.49.0_optionato_ob7b236jhsabowihs5e6dacmlm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import colors_vxIuzqczNZ from "/app/node_modules/.pnpm/@nuxt+ui@2.10.0_axios@0.27.2_change-case@3.1.0_rollup@3.29.0_ts-node@10.9.1_@swc+core@1.3.82__s75o4ckn7cjpyzvnxlyee7svhq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_fiSFib7yMk from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.3.0_rollup@3.29.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import view_transitions_client_W7Ld8Rk4Be from "/app/node_modules/.pnpm/nuxt@3.7.1_@parcel+watcher@2.3.0_@types+node@18.17.14_encoding@0.1.13_eslint@8.49.0_optionato_ob7b236jhsabowihs5e6dacmlm/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_LqRhZA77JZ from "/app/node_modules/.pnpm/nuxt@3.7.1_@parcel+watcher@2.3.0_@types+node@18.17.14_encoding@0.1.13_eslint@8.49.0_optionato_ob7b236jhsabowihs5e6dacmlm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import marker_io_client_3vX6FuptAi from "/app/packages/web/plugins/marker-io.client.ts";
import query_Ux2Qt90xcP from "/app/packages/web/plugins/query.ts";
export default [
  revive_payload_client_kG6mp5Xpfk,
  unhead_WpxwByvOnn,
  router_EJJu3XmsQk,
  sentry_client_Ub7jFNgAqV,
  trpc_client_3tK3TWUrV0,
  plugin_vue3_38Il8GJ5wi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Ctuj9Yxhme,
  colors_vxIuzqczNZ,
  plugin_client_fiSFib7yMk,
  view_transitions_client_W7Ld8Rk4Be,
  chunk_reload_client_LqRhZA77JZ,
  marker_io_client_3vX6FuptAi,
  query_Ux2Qt90xcP
]